import { request } from './httpService';

const API_VERSION = 'v1';
const pathPrefix = `/api/${API_VERSION}/`;

const OrgAdministrationService = {
  getOrgUsers: async (orgId: number) => {
    return request({
      url: `${pathPrefix}org-administration/orgs/${orgId}/users/`,
      method: 'get',
    });
  },
  getTenantById: async (tenantId: number) => {
    return request({
      url: `${pathPrefix}org-administration/tenants/${tenantId}/`,
      method: 'get',
    });
  },
};

export default OrgAdministrationService;
