import { RGBAType } from './commonTypes';

export enum FileViwerMode {
  EDIT = 'edit',
  VIEW = 'view',
}

export type AnnotationConfigTypes = {
  displayAnnotations?: boolean;
  documentSnaphostId: number;
  enableAnnotationEditing?: boolean;
  assessmentId?: number;
};

export type TextCoords = {
  x_left: number;
  x_right: number;
  y_top: number;
  y_bottom: number;
  page: number;
};

export type TextCoordinatesType = {
  x_left: number;
  x_right: number;
  y_top: number;
  y_bottom: number;
  page: number;
  highlight_color?: RGBAType;
};
