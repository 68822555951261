import { useAtomValue } from 'jotai';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import {
  Accordion,
  Button,
  Flex,
  Grid,
  Group,
  Stack,
  Switch,
  Text,
  Tooltip,
  useMantineTheme,
} from '@mantine/core';
import { LinguisticErrors } from 'Constants/index';
import { setHighlightedCoordinates } from 'Slices/activeDocumentSlice';
import { LinguisticErrorType } from 'Types/linguisticTypes';
import { getDiscussionTitleAndContent, splitUsername } from 'Utils/helper';
import { showErrorNotification } from 'Utils/notifications';

import classes from './GrammaticalSpellCheck.module.scss';
import { linguisticErrorsAtom } from './spellingGrammarAtom';

interface GrammaticalSectionProps {
  handleErrorAssignment: (snapshotId: number, errorId: number) => void;
  ignoreError: (snapshotId: number, errorId: number) => void;
}

const GrammaticalSection: React.FC<GrammaticalSectionProps> = ({
  handleErrorAssignment,
  ignoreError,
}) => {
  const linguisticErrors = useAtomValue(linguisticErrorsAtom);
  const [grammarErrors, setGrammarErrors] = useState<LinguisticErrorType[]>([]);
  const dispatch = useDispatch();
  const theme = useMantineTheme();

  console.log(linguisticErrors, 'lingusicErrors');

  useEffect(() => {
    const onlyGrammarErrors = linguisticErrors.filter(
      (error) =>
        error.error_type === LinguisticErrors.GRAMMAR_ERROR && !error.is_ignored
    );
    setGrammarErrors(onlyGrammarErrors);
  }, [linguisticErrors]);

  const handleHighlight = (error: LinguisticErrorType) => {
    // currently we are only highlighting the first word block
    if (!(error && error.word_blocks[0]?.bounding_box)) {
      return showErrorNotification(
        'Unable to highlight text: The text is either non-searchable or part of an image.'
      );
    }
    const coordinates = error.word_blocks.map((block) => ({
      ...block.bounding_box,
      page: block.page_number,
    }));
    dispatch(setHighlightedCoordinates(coordinates));
  };

  return (
    <Accordion.Item value="grammatical">
      <Accordion.Control>
        <Flex mr="md" justify="space-between" align="center">
          <Text tt="uppercase" size="sm" mr="sm">
            Grammatical ({grammarErrors.length})
          </Text>
          <Text size="sm" c={theme.colors.pink[6]}>
            6 results awaiting response
          </Text>
        </Flex>
      </Accordion.Control>
      <Accordion.Panel>
        {grammarErrors.map((error) => (
          <Stack
            className={classes.grammaticalErrorCard}
            gap="xs"
            pb="xs"
            style={{ borderBottom: '1px solid #e0e0e0' }}
            key={error.id}
            p="md"
            onClick={() => handleHighlight(error)}
          >
            <Grid align="center">
              <Grid.Col span={9}>
                <Text size="sm" mr="sm" lineClamp={1}>
                  {error.error_message}
                </Text>
              </Grid.Col>
              <Grid.Col span={3}>
                <Switch
                  labelPosition="left"
                  label="Ignore"
                  checked={error.is_ignored}
                  onChange={(event) => {
                    if (event.currentTarget.checked) {
                      ignoreError(error.document_snapshot_id, error.id);
                    }
                  }}
                />
              </Grid.Col>
            </Grid>
            <Stack gap={0}>
              <Text c="#069B03" size="sm" fw={500}>
                Suggestions:{' '}
              </Text>
              <Text size="sm" c="#666">
                {error.suggested_corrections
                  .map((suggestion) => suggestion)
                  .join(', ')}
              </Text>
            </Stack>
            {error.comment_thread_id && (
              <Tooltip
                label={getDiscussionTitleAndContent(error)?.firstPostContent}
              >
                <Text mt="xs" size="sm" fs="italic" c="#666">
                  <span style={{ fontWeight: 500, color: '#008BD9' }}>
                    {
                      splitUsername(getDiscussionTitleAndContent(error)?.title)
                        .username
                    }
                  </span>
                  <span>
                    {
                      splitUsername(getDiscussionTitleAndContent(error)?.title)
                        .rest
                    }
                  </span>
                </Text>
              </Tooltip>
            )}
            <Group gap="xl">
              <Button
                size="sm"
                variant="link"
                onClick={() =>
                  handleErrorAssignment(error.document_snapshot_id, error.id)
                }
              >
                Assign User
              </Button>
            </Group>
          </Stack>
        ))}
        ;
      </Accordion.Panel>
    </Accordion.Item>
  );
};

export default GrammaticalSection;
