import { request } from './httpService';

const API_VERSION = 'v1';
const pathPrefix = `/api/${API_VERSION}/reviews/`;

const LinguisticService = {
  getLinguisticErrors: async (snapshotId: number) => {
    return request({
      url: `${pathPrefix}snapshots/${snapshotId}/linguistic-errors/`,
      method: 'get',
    });
  },
  getLinguisticErrorById: async (snapshotId: number, id: number) => {
    return request({
      url: `${pathPrefix}snapshots/${snapshotId}/linguistic-errors/${id}/`,
      method: 'get',
    });
  },
  addToComment: async (
    commentId: number,
    errorId: number,
    snapshotId: number
  ) => {
    return request({
      url: `${pathPrefix}snapshots/${snapshotId}/linguistic-errors/${errorId}/add_to_comment/`,
      method: 'patch',
      data: {
        comment_thread_id: commentId,
      },
    });
  },
  markAsIgnored: async (errorId: number, snapshotId: number) => {
    return request({
      url: `${pathPrefix}snapshots/${snapshotId}/linguistic-errors/${errorId}/mark_as_ignored/`,
      method: 'patch',
    });
  },
};

export default LinguisticService;
