import React, { useState, useEffect, useRef } from 'react';

import { Box, Flex, Loader, Center, Switch } from '@mantine/core';
import SnapshotService from 'Api/snapshotService';
import useLoading from 'Src/hooks/useLoading';
import { PageClusterWordMap } from 'Types/extractorTypes';
import { Serializer } from 'Utils/extractor-sdk/serialization';

import TextVisualizer from './TextVisualizer';

type TxtFileViewerProps = {
  documentId: number | undefined;
};

const TxtFileViewer: React.FC<TxtFileViewerProps> = ({ documentId }) => {
  const [jsonContent, setJsonContent] = useState<PageClusterWordMap | string>(
    ''
  );
  const [loading, handleLoading] = useLoading(false);
  const [error, setError] = useState<string | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [showTextView, setShowTextView] = useState(false);
  const [textContent, setTextContent] = useState<string | null>(null);

  const fetchDocument = async () => {
    if (!documentId) return;

    handleLoading.start();
    try {
      const parsedResponse =
        await SnapshotService.getParsedSnapshot(documentId);
      if (!parsedResponse.data) return;
      const { results } = parsedResponse.data;
      const url = results?.[0]?.file;
      if (!url) return;
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const contentType = response.headers.get('content-type');
      if (contentType && contentType.includes('application/json')) {
        const jsonResponse = await response.json();
        if (Object.keys(jsonResponse).length === 0) {
          return setError('Extraction has not been run or is in progress.');
        }
        const jsonVersion = jsonResponse.version;
        if (jsonVersion !== 'v1' && jsonVersion !== 'v2') {
          throw new Error(
            `Version ${jsonVersion} is not supported. Currently only v1 and v2 is supported.`
          );
        }
        const deserializedDocument = Serializer.deserialize(jsonResponse);
        setJsonContent(
          deserializedDocument.pageClusterWordMap as PageClusterWordMap
        );
        setTextContent(jsonResponse.full_text);
      } else {
        // for backwards compatibility
        if (contentType && !contentType.includes('binary/octet-stream')) {
          throw new Error(`Unsupported content type: ${contentType}`);
        }
        const text = await response.text();
        setShowTextView(true);
        setTextContent(text);
      }
    } catch (error: any) {
      setError(error.message);
    } finally {
      handleLoading.stop();
    }
  };

  useEffect(() => {
    if (!documentId) {
      handleLoading.stop();
      return;
    }
    (async () => {
      await fetchDocument();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentId]); // Dependency on URL to refetch when it changes

  if (documentId === undefined) {
    return (
      <Flex pt={80} justify="center" w="100%">
        <p>Invalid document</p>
      </Flex>
    );
  }

  if (error)
    return (
      <Flex pt={80} justify="center" w="100%">
        Error: {error}
      </Flex>
    );

  return (
    <Box
      style={{
        whiteSpace: 'pre-wrap',
        background: '#fffff1',
        height: 'calc(100vh - 80px)',
        overflowY: 'scroll',
      }}
      mih={100}
      p={25}
      id="content-container"
      ref={containerRef}
    >
      {!loading && (
        <Flex direction={'column'}>
          <Flex justify={'flex-end'} mb={'xs'}>
            <Switch
              checked={showTextView}
              onChange={(event) => setShowTextView(event.currentTarget.checked)}
              label="Text View"
              disabled={!jsonContent}
            />
          </Flex>
          {showTextView ? (
            textContent
          ) : (
            <TextVisualizer documentText={jsonContent} />
          )}
        </Flex>
      )}
      {loading && (
        <Center>
          <Loader />
        </Center>
      )}
    </Box>
  );
};

export default TxtFileViewer;
