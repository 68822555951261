import { startCase } from 'lodash';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import {
  Grid,
  Stack,
  Text,
  Table,
  Button,
  Drawer,
  TextInput,
  Box,
  Flex,
  Title,
  Textarea,
  Checkbox,
  Group,
  Divider,
  ScrollArea,
  List,
} from '@mantine/core';
import { upperFirst, useDisclosure } from '@mantine/hooks';
import {
  IconAutomaticGearbox,
  IconCircleCheck,
  IconProgressCheck,
  IconTestPipe,
  IconWand,
} from '@tabler/icons-react';
import DocumentService from 'Api/documentService';
import ExpressionService from 'Api/expressionService';
import RuleService from 'Api/ruleService';
import { EvaluationStaus } from 'Constants/index';
import useLoading from 'Src/hooks/useLoading';
import { EphemeralExecuteDataType } from 'Types/commonTypes';
import { DocumentDataType, DocumentSnapshotType } from 'Types/docTypes';
import { EphemeralResponseType } from 'Types/ephemeralResponseType';
import { RuleType } from 'Types/ruleTypes';
import {
  showErrorNotification,
  showSuccessNotification,
} from 'Utils/notifications';

const translateObjectToReadableFormat = (data: any) => {
  const renderObject = (obj: any) => {
    return Object.keys(obj).map((key) => {
      const value = obj[key];
      if (Array.isArray(value)) {
        return (
          <Box mb="md" key={key}>
            <Title fw={500} order={6}>
              {startCase(key)}
            </Title>
            <List spacing="md">
              {value.map((item, index) => (
                <List.Item key={index}>
                  {typeof item === 'object' ? renderObject(item) : item}
                </List.Item>
              ))}
            </List>
          </Box>
        );
      } else if (typeof value === 'object' && value !== null) {
        return (
          <Box mb="md" key={key}>
            <Title fw={500} order={6}>
              {startCase(key)}
            </Title>
            {renderObject(value)}
          </Box>
        );
      } else {
        return (
          <div key={key}>
            <Title fw={500} order={6}>
              {upperFirst(key)}
            </Title>
            <Text size="sm">{value}</Text>
            <Divider my={2} />
          </div>
        );
      }
    });
  };

  if (Array.isArray(data)) {
    return (
      <Stack>
        {data.map((item, index) => (
          <div key={index}>{renderObject(item)}</div>
        ))}
        <Divider my="sm" />
      </Stack>
    );
  }

  return <Stack>{renderObject(data)}</Stack>;
};

type RuleWithExpressionType = RuleType & { expression: string };

const RuleStudio = () => {
  const { ruleId } = useParams();
  const navigate = useNavigate();
  const [drawerOpened, { open, close }] = useDisclosure(false);
  const [selectedRows, setSelectedRows] = useState<DocumentSnapshotType[]>([]);
  // const [selectedDocumentsForTesting, setSelectedDocumentsForTesting] =
  //   useState<DocumentSnapshotType[]>([]);
  const [ruleDescription, setRuleDescription] = useState<string>('');
  const [ruleExample, setRuleExample] = useState<string>('');
  const [ruleGenerationResponse, setRuleGenerationResponse] =
    useState<RuleWithExpressionType | null>(null);
  const [ruleExpression, setRuleExpression] = useState<string>('');
  const [ruleSummary, setRuleSummary] = useState<string>('');
  const [loading, handleLoading] = useLoading(false);
  const [documentsTestingLoader, handleDocumentsTestingLoader] =
    useLoading(false);
  const [docTestStatuses, setDocTestStatuses] = useState<{
    [key: number]: any;
  }>('');
  const [documentTestResults, setDocumentTestResults] = useState<any[]>([]);
  const [showTestResult, setShowTestResult] = useState<boolean>(false);

  const [documentsList, setDocumentsList] = useState<DocumentSnapshotType[]>(
    []
  );
  const [ruleTitle, setRuleTitle] = useState<string>('');

  useEffect(() => {
    if (ruleId !== 'new') {
      if (ruleId && !isNaN(parseInt(ruleId))) {
        fetchRuleById(parseInt(ruleId));
      } else {
        showErrorNotification(`Invalid URL, rule doesn't exist`);
        navigate('/not-found');
      }
    }
  }, [ruleId, navigate]);

  // useEffect(() => {
  //   console.log(selectedRows);
  // }, [selectedRows]);

  const fetchRuleById = async (ruleId: number) => {
    const { data } = await RuleService.getRulesById(ruleId);
    setRuleDescription(data.description);
    setRuleTitle(data.title);
    // setRuleSummary(data.summary);
    setRuleExpression(data.expression.script);
    setRuleGenerationResponse(data);
  };

  const handleGenerateRule = async () => {
    handleLoading.start();
    const { data } = await RuleService.generateRule({
      description: ruleDescription,
      example: ruleExample,
    });
    // Simulate rule generation
    setRuleTitle(data.title);
    setRuleGenerationResponse(data);
    setRuleExpression(data.expression);
    setRuleSummary(data.summary);
    handleLoading.stop();
  };

  useEffect(() => {
    fetchDocuments();
  }, []);

  const fetchDocuments = async () => {
    const { data } = await DocumentService.getDocuments();
    const favoriteSnapshots = data.results.map(
      (doc: DocumentDataType) => doc.favorite_snapshot
    );
    console.log(favoriteSnapshots, 'favoriteSnapshots');
    setDocumentsList(favoriteSnapshots);
    console.log(data);
  };

  const extractExplanationInfo = (result: EphemeralResponseType): any[] => {
    console.log(result, 'extractExplanationInfo');
    const extractedExplanations: any[] = [];
    const execBody = result?.output_context?.exec_tree?.body;
    if (
      execBody.exec_explanation_ctx &&
      execBody.exec_explanation_ctx.is_llm_generated
    ) {
      execBody.exec_explanation_ctx.all_responses.forEach((response) => {
        extractedExplanations.push(response);
      });
    }
    return extractedExplanations;
  };

  useEffect(() => {
    console.log(documentTestResults, 'documentTestResults');
  }, [documentTestResults]);

  const testAttachedDocuments = async () => {
    handleDocumentsTestingLoader.start();
    const promises = selectedRows.map((doc) => {
      const context = {
        document_snapshot_id: doc.id,
      };
      const ephemeralExecuteData: EphemeralExecuteDataType = {
        script: ruleExpression || '',
        context,
        allowed_names: ['content', 'document', 'document_plaintext'],
      };

      return ExpressionService.ephemeralExecute(ephemeralExecuteData)
        .then(({ data }) => {
          const rationales = extractExplanationInfo(data);
          console.log(rationales, 'rationales');
          setDocTestStatuses((prevStatuses) => ({
            ...prevStatuses,
            [doc.id]: {
              rationales,
              error: false,
              status: EvaluationStaus.COMPLETED,
            },
          }));
          return data;
        })
        .catch((error) => {
          setDocTestStatuses((prevStatuses) => ({
            ...prevStatuses,
            [doc.id]: { error: true, status: EvaluationStaus.FAILED },
          }));
          throw error;
        });
    });

    try {
      await Promise.allSettled(promises);
    } catch (e) {
      console.log(e);
    } finally {
      handleDocumentsTestingLoader.stop();
    }
  };

  // const cleanDocumentTestResults = (docResults: any) => {
  //   const { error, status, ...rest } = docResults;
  //   return rest;
  // };

  const rows = selectedRows.map((element, index) => (
    <Table.Tr
      key={index}
      style={{
        backgroundColor: selectedRows.map((row) => row.id).includes(index)
          ? 'var(--mantine-color-blue-light)'
          : undefined,
      }}
    >
      <Table.Td>
        <Text size="sm" maw={300}>
          {element.file_original_name}
        </Text>{' '}
      </Table.Td>
      <Table.Td>
        <Group align="center" gap="lg">
          {upperFirst(docTestStatuses[element.id]?.status || 'Pending')}
          {docTestStatuses[element.id]?.status ===
            EvaluationStaus.COMPLETED && (
            <Button
              onClick={() => {
                setShowTestResult(true);
                setDocumentTestResults(docTestStatuses[element.id] || {});
              }}
              size="sm"
              variant="link"
            >
              View more
            </Button>
          )}
        </Group>
      </Table.Td>
    </Table.Tr>
  ));

  return (
    <Flex direction="column" style={{ height: 'calc(100vh - 80px)' }}>
      <Flex align="center">
        <Group align="center" gap="xs">
          <IconWand /> Rule Studio
        </Group>
      </Flex>
      <Flex direction="column" gap="xl" p="xl">
        <Box flex="1">
          <Grid style={{ height: '100%' }}>
            {/* First Half */}
            <Grid.Col
              span={5}
              style={{ display: 'flex', flexDirection: 'column' }}
            >
              <Stack>
                <Textarea
                  label="Rule Description"
                  minRows={3}
                  placeholder="Enter text here"
                  value={ruleDescription}
                  onChange={(event) =>
                    setRuleDescription(event.currentTarget.value)
                  }
                  autosize
                />
                <Textarea
                  label="Example"
                  placeholder="Enter text here"
                  value={ruleExample}
                  minRows={2}
                  onChange={(event) =>
                    setRuleExample(event.currentTarget.value)
                  }
                  autosize
                />
                <Flex justify="flex-end">
                  <Button
                    leftSection={<IconProgressCheck />}
                    variant="light"
                    onClick={handleGenerateRule}
                    disabled={!ruleDescription}
                    loading={loading}
                    loaderProps={{ type: 'dots' }}
                    size="xs"
                  >
                    Generate Rule
                  </Button>
                </Flex>
              </Stack>
            </Grid.Col>

            <Grid.Col span={6} offset={1} style={{ overflowY: 'auto' }}>
              <Box h="100%">
                {!ruleGenerationResponse ? (
                  <Flex
                    align="center"
                    justify="center"
                    direction="column"
                    h="100%"
                    gap="md"
                  >
                    <IconAutomaticGearbox
                      stroke={1}
                      fontWeight={100}
                      color="#868e96"
                      size={100}
                    />
                    <Text c="dimmed" size="sm">
                      Add Rule Description & click "Generate Rule" to see the
                      generated rule.
                    </Text>
                  </Flex>
                ) : (
                  <Stack>
                    <TextInput
                      size="sm"
                      value={ruleTitle}
                      onChange={(event) =>
                        setRuleTitle(event.currentTarget.value)
                      }
                      label="Rule Title"
                    ></TextInput>
                    {/* <TextInput
                      size="sm"
                      value={ruleTitle}
                      onChange={(event) =>
                        setRuleTitle(event.currentTarget.value)
                      }
                      label="Rule Summary"
                    ></TextInput> */}
                    <Text size="sm">
                      Summary : {ruleSummary || 'No summary available'}
                    </Text>
                    <Textarea
                      autosize
                      size="sm"
                      value={ruleExpression || ''}
                      onChange={(event) => {
                        const { value } = event.currentTarget;
                        if (value) {
                          setRuleExpression(value);
                        }
                      }}
                      label="Generated Rule"
                    ></Textarea>
                    {/* <Text size="sm">Generated Rule</Text>
                    <Code>{ruleGenerationResponse?.expression || ''}</Code> */}

                    <Flex justify="flex-end">
                      <Button
                        leftSection={<IconCircleCheck />}
                        variant="primary"
                        size="xs"
                        onClick={async () => {
                          try {
                            if (ruleGenerationResponse?.id) {
                              await RuleService.updateRules(
                                ruleGenerationResponse.id,
                                {
                                  title: ruleTitle,
                                  description: ruleDescription,
                                  expression: {
                                    script: ruleExpression,
                                  },
                                  is_active: true,
                                }
                              );
                              showSuccessNotification(
                                'Rule updated successfully'
                              );
                            } else {
                              const { data } = await RuleService.createRules({
                                title: ruleTitle,
                                description: ruleDescription,
                                expression: {
                                  script: ruleExpression,
                                },
                                is_active: true,
                              });
                              if (data && data.id) {
                                // silently update the url
                                navigate(`/rule-studio/${data.id}`, {
                                  replace: true,
                                });
                                showSuccessNotification(
                                  'Rule created successfully'
                                );
                                // setRuleTitle(data.title);
                                // setRuleDescription(data.description);
                                // setRuleGenerationResponse(data);
                              } else {
                                showErrorNotification(
                                  'Something went wrong while creating rule'
                                );
                              }
                            }
                          } catch (e) {
                            showErrorNotification(
                              'Something went wrong while creating rule'
                            );
                          }
                        }}
                      >
                        {ruleGenerationResponse?.id
                          ? `Update Rule`
                          : `Save Rule`}
                      </Button>
                    </Flex>
                  </Stack>
                )}
              </Box>
            </Grid.Col>
          </Grid>
        </Box>
        <Divider
          label={<Title order={4}>{`Test Documents >>`}</Title>}
          labelPosition="left"
          my="xs"
          size="lg"
        />
        <Box flex={1} style={{ padding: '1rem' }}>
          <Flex justify="space-between" align="center">
            <Group align="center" gap="lg">
              <Button
                variant="outline"
                onClick={open}
                size="xs"
                disabled={!ruleExpression}
              >
                Add / Remove Document
              </Button>
              <Button
                leftSection={<IconTestPipe />}
                variant="light"
                onClick={testAttachedDocuments}
                size="xs"
                loading={documentsTestingLoader}
                loaderProps={{ type: 'dots' }}
                disabled={!selectedRows.length}
              >
                Run Tests
              </Button>
            </Group>
          </Flex>
          <ScrollArea h={`calc(50vh - 125px)`}>
            <Table mt="md" withTableBorder withRowBorders withColumnBorders>
              <Table.Thead>
                <Table.Tr>
                  {/* <Table.Th /> */}
                  <Table.Th>File Name</Table.Th>
                  <Table.Th>Status</Table.Th>
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody>{rows}</Table.Tbody>
            </Table>
          </ScrollArea>
        </Box>
      </Flex>
      <Drawer
        opened={drawerOpened}
        onClose={close}
        title="Attach New Document"
        padding="xl"
        size="xl"
        position="right"
      >
        <Box size="sm">
          {documentsList.map((doc) => (
            <Box mb="sm" key={doc.id}>
              <Group>
                <Checkbox
                  checked={selectedRows.map((row) => row.id).includes(doc.id)}
                  onChange={(event) => {
                    if (event.target.checked) {
                      const documentToBeAdded = documentsList.find(
                        (document) => document.id === doc.id
                      );
                      if (!documentToBeAdded) return;
                      setSelectedRows([...selectedRows, documentToBeAdded]);
                    } else {
                      setSelectedRows(
                        selectedRows.filter((row) => row.id !== doc.id)
                      );
                    }
                  }}
                  label={doc.file_original_name}
                />
              </Group>
            </Box>
          ))}
        </Box>
      </Drawer>
      <Drawer
        opened={showTestResult}
        onClose={() => setShowTestResult(false)}
        title="Test Results"
        padding="xl"
        size="xl"
        position="right"
      >
        {translateObjectToReadableFormat(documentTestResults)}
      </Drawer>
    </Flex>
  );
};

export default RuleStudio;
