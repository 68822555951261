import React from 'react';

import { Text, Accordion } from '@mantine/core';
import { PageClusterWordMap } from 'Types/extractorTypes';

import DisplayNestedClusters from './DisplayNestedClusters';

type TextVisualizerProps = {
  documentText: PageClusterWordMap | string;
};

const TextVisualizer: React.FC<TextVisualizerProps> = ({ documentText }) => {
  if (typeof documentText === 'string') return documentText;
  return (
    <Accordion multiple defaultValue={['0']}>
      {documentText &&
        Object.keys(documentText).map((item, index) => {
          const pageData = documentText[item];
          const pageNumber = Number(item);

          return (
            <Accordion.Item value={item} key={index}>
              <Accordion.Control>
                <Text c="#757575">Page {pageNumber}</Text>
              </Accordion.Control>
              <Accordion.Panel>
                <DisplayNestedClusters
                  pageData={pageData}
                  pageNumber={pageNumber}
                />
              </Accordion.Panel>
            </Accordion.Item>
          );
        })}
    </Accordion>
  );
};

export default TextVisualizer;
