import { Dispatch, SetStateAction, useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { Box, Flex, Text, Tabs } from '@mantine/core';
import ChatWindow from 'Components/chat-window/ChatWindow';
import TxtFileViewer from 'Components/txt-file-viewer/TxtFileViewer';
import { RootState } from 'Src/redux/store';
import { MessageTypes } from 'Types/chatTypes';

import CommentsTab from './comments-tab/CommentsTab';
import GrammaticalTab from './grammatical-spell-tab/GrammaticalSpellTab';
import AssessmentOverview from './overview/AssessmentOverview';

type AssessmentTabProps = {
  changeMainTab: (tab: string) => void;
  toggleComments: Dispatch<SetStateAction<boolean>>;
};

const AssessmentTab: React.FC<AssessmentTabProps> = ({
  toggleComments,
  ...rest
}) => {
  const documentData = useSelector(
    (state: RootState) => state.activeDocument.data
  );
  const [activeMainTab, setActiveMainTab] = useState('assessment');
  const [copilotMessages, setCopilotMessages] = useState<MessageTypes[]>([]);
  const location = useLocation();
  const ruleEvalRefs = useRef<{ [key: number]: HTMLDivElement | null }>({});

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const commentId = searchParams.get('commentId');
    const highlightedRuleEvalId = searchParams.get('ruleEvalId');

    if (commentId) {
      setActiveMainTab('comments');
    } else if (highlightedRuleEvalId) {
      setActiveMainTab('assessment');

      // Use setTimeout to ensure the ruleEvalRefs are populated
      setTimeout(() => {
        const ruleEvalElement =
          ruleEvalRefs.current[Number(highlightedRuleEvalId)];
        if (ruleEvalElement) {
          ruleEvalElement.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          });
        }
      }, 0);
    }
  }, [location.search]);

  useEffect(() => {
    toggleComments(activeMainTab === 'comments');
  }, [activeMainTab, toggleComments]);

  const mainTabs = [
    { label: 'Rule Evaluation', value: 'assessment' },
    { label: 'Grammatical', value: 'grammatical' },
    { label: 'Text Extract', value: 'extract' },
    {
      label: 'Co-Pilot',
      value: 'copilot',
    },
    { label: 'Comments', value: 'comments' },
  ];

  const handleTabChange = (value: string | null) => {
    if (value !== null) {
      setActiveMainTab(value);
    }
  };

  return (
    <Tabs
      variant="pills"
      defaultValue="setup"
      value={activeMainTab}
      onChange={handleTabChange}
      keepMounted={false}
    >
      <Flex direction="column" w={'100%'}>
        <Box
          w={'100%'}
          pos={'sticky'}
          top={'0'}
          bg="white"
          style={{ zIndex: 99 }}
          mih="83vh"
        >
          <Tabs.List style={{ borderBottom: '1px solid #dee2e6' }}>
            {mainTabs.map((tab, index) => (
              <Tabs.Tab
                style={{ backgroundColor: 'none' }}
                color="white"
                value={tab.value}
                key={index}
                size="xs"
              >
                <Text
                  c="black"
                  size="sm"
                  fw={activeMainTab === tab.value ? 700 : 500}
                >
                  {tab.label}
                </Text>
              </Tabs.Tab>
            ))}
          </Tabs.List>

          <Flex flex={1} w={'100%'}>
            <Tabs.Panel value="assessment" w="100%">
              <AssessmentOverview
                {...rest}
                setActiveTab={handleTabChange}
                ruleEvalRefs={ruleEvalRefs}
              />
            </Tabs.Panel>
            <Tabs.Panel value="copilot" w="100%">
              <ChatWindow
                documentData={documentData}
                setCopilotMessages={setCopilotMessages}
                copilotMessages={copilotMessages}
              />
            </Tabs.Panel>
            <Tabs.Panel value="extract" w="100%">
              <TxtFileViewer documentId={documentData?.favorite_snapshot?.id} />
            </Tabs.Panel>
            <Tabs.Panel value="comments" w="100%">
              <CommentsTab />
            </Tabs.Panel>
            <Tabs.Panel value="grammatical" w="100%">
              <GrammaticalTab />
            </Tabs.Panel>
          </Flex>
        </Box>
      </Flex>
    </Tabs>
  );
};

export default AssessmentTab;
