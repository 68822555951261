import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  Button,
  Flex,
  Title,
  Modal,
  Stack,
  Textarea,
  Autocomplete,
} from '@mantine/core';
import { CommentThreadsService } from 'Api/commentThreadsService';
import LinguisticService from 'Api/linguisticService';
import OrgAdministrationService from 'Api/orgAdministrationService';
import { RootState } from 'Src/redux/store';
import { UserType } from 'Types/userTypes';
import {
  showErrorNotification,
  showSuccessNotification,
} from 'Utils/notifications';

type AssignUserModalProps = {
  open: boolean;
  handleModal: { close: () => void };
  errorToAssign?: { errorId: number; snapshotId: number };
};

const AssignUserModal: React.FC<AssignUserModalProps> = ({
  open,
  handleModal,
  errorToAssign,
}) => {
  const [description, setDescription] = useState<string>('');
  const [orgUsers, setOrgUsers] = useState<Partial<UserType>[]>([]);
  const [assignedUser, setAssignedUser] = useState<string | undefined>(
    undefined
  );
  const { user } = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    fetchUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const assignUserError = async () => {
    if (!errorToAssign || !errorToAssign.snapshotId || !errorToAssign.errorId)
      return showErrorNotification('Invalid error');
    try {
      const commentData = {
        status: 'open',
        discussion: {
          title: `#${assignedUser} is assigned on this task`,
          posts: [
            {
              content: description,
            },
          ],
        },
        document_snapshot_id: errorToAssign?.snapshotId,
        annotations: [],
      };
      // first create a comment and then call add_to_comment
      // and attach comment and error_id
      const { data: commentResponse } =
        await CommentThreadsService.createCommentThreads(commentData);
      if (errorToAssign?.errorId) {
        console.log(commentResponse, 'commentResponse');
        const { data } = await LinguisticService.addToComment(
          commentResponse.id,
          errorToAssign?.errorId,
          commentResponse.document_snapshot_id
        );
        console.log(data);
        showSuccessNotification('Error assigned successfully');
      } else {
        showErrorNotification('Something went wrong while assigning error');
      }
      console.log(commentResponse);
    } catch (error: any) {
      console.log(error);
      if (error instanceof Error) {
        showErrorNotification(error.message || 'Error assigning user to error');
      } else {
        showErrorNotification('Error assigning user to error');
      }
    } finally {
      handleModal.close();
    }
  };

  const fetchUsers = async () => {
    if (user?.currentTenant) {
      const { data } = await OrgAdministrationService.getOrgUsers(
        user.currentTenant
      );
      if (data.results) {
        const users = data.results.map(({ user }: any) => user);
        console.log(users, 'data.results');
        setOrgUsers(users);
      }
    }
  };

  return (
    <Modal
      size="lg"
      variant="pink-line"
      opened={open}
      onClose={handleModal.close}
      title={
        <Flex justify="space-between">
          <Title order={4}>
            Assign <strong>User</strong>
          </Title>
        </Flex>
      }
    >
      <Stack gap="xs">
        <Autocomplete
          label="Select User"
          placeholder="Search users"
          data={orgUsers.map((user) => {
            return {
              value: user.id?.toString() || '',
              label: user.username,
            };
          })}
          value={assignedUser}
          onChange={setAssignedUser}
        />
        <Textarea
          value={description}
          label="Description"
          placeholder="Enter description"
          onChange={(event) => setDescription(event.currentTarget.value)}
          size="sm"
        />
        <Flex justify="flex-end">
          <Button
            size="sm"
            variant="filled-shadow"
            mt="lg"
            onClick={assignUserError}
          >
            Assign
          </Button>
        </Flex>
      </Stack>
    </Modal>
  );
};

export default AssignUserModal;
