import { AddToDictionaryPayloadType } from 'Types/linguisticTypes';

import { request } from './httpService';

const API_VERSION = 'v1';
const pathPrefix = `/api/${API_VERSION}/`;

const CommonService = {
  getMetaContentTypes: async () => {
    return request({
      url: `${pathPrefix}meta/content-types/`,
      method: 'get',
    });
  },
  // /api/v1/reviews/add-to-dictionary/
  addToDictionary: async (data: AddToDictionaryPayloadType) => {
    return request({
      url: `${pathPrefix}reviews/add-to-dictionary/`,
      method: 'post',
      data: data,
    });
  },
};

export default CommonService;
