import React, { useCallback, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';

import { Text, Accordion } from '@mantine/core';
import { setHighlightedCoordinates } from 'Slices/activeDocumentSlice';
import { Words, TNestedblock, BoundingBoxType } from 'Types/extractorTypes';

type DisplayNestedClustersProps = {
  pageData: TNestedblock[] | Words[];
  pageNumber: number;
};

const DisplayNestedClusters: React.FC<DisplayNestedClustersProps> = ({
  pageData,
  pageNumber,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();

  const updateHighlight = useCallback(() => {
    const selection = window.getSelection();
    if (!selection || selection.isCollapsed) {
      dispatch(setHighlightedCoordinates(null));
      return;
    }

    const range = selection.getRangeAt(0);
    const startElement = range.startContainer.parentElement;
    const endElement = range.endContainer.parentElement;

    if (!startElement || !endElement) return;

    const startBox = getBoundingBoxFromElement(startElement);
    const endBox = getBoundingBoxFromElement(endElement);

    const startPage = Number(startElement.getAttribute('data-page'));

    if (startBox && endBox) {
      dispatch(
        setHighlightedCoordinates([
          {
            x_left: Math.min(startBox.x_left, endBox.x_left),
            y_top: Math.min(startBox.y_top, endBox.y_top),
            x_right: Math.max(startBox.x_right, endBox.x_right),
            y_bottom: Math.max(startBox.y_bottom, endBox.y_bottom),
            page: startPage,
          },
        ])
      );
    }
  }, [dispatch]);

  const getBoundingBoxFromElement = (
    element: Element
  ): BoundingBoxType | null => {
    const x_left = element.getAttribute('data-word-x-left');
    const y_top = element.getAttribute('data-word-y-top');
    const x_right = element.getAttribute('data-word-x-right');
    const y_bottom = element.getAttribute('data-word-y-bottom');
    if (x_left && y_top && x_right && y_bottom) {
      return {
        x_left: Number(x_left),
        y_top: Number(y_top),
        x_right: Number(x_right),
        y_bottom: Number(y_bottom),
      };
    }
    return null;
  };

  useEffect(() => {
    const handleSelectionChange = () => {
      updateHighlight();
    };

    document.addEventListener('selectionchange', handleSelectionChange);
    return () => {
      document.removeEventListener('selectionchange', handleSelectionChange);
    };
  }, [updateHighlight]);

  const getLineText = (lineItems: Words) => {
    return lineItems.map((wordItem, wordIndex) => (
      <>
        <span
          key={wordIndex}
          data-page={pageNumber}
          data-word-x-left={wordItem.bounding_box?.x_left}
          data-word-y-top={wordItem.bounding_box?.y_top}
          data-word-x-right={wordItem.bounding_box?.x_right}
          data-word-y-bottom={wordItem.bounding_box?.y_bottom}
          style={{
            fontWeight: wordItem.font_style?.font_weight,
            fontFamily: wordItem.font_style?.font_family,
            fontSize: wordItem.font_style?.font_size
              ? `${Math.min(Number(wordItem.font_style.font_size) * 2, 16)}px`
              : 'inherit',
          }}
        >
          {wordItem.text}
        </span>
        {wordIndex >= lineItems.length - 1 ||
        wordItem.font_style?.is_prefix ||
        (wordIndex < lineItems.length - 1 &&
          lineItems[wordIndex + 1].font_style?.is_suffix)
          ? ''
          : ' '}
      </>
    ));
  };
  return (
    <div ref={containerRef}>
      <Accordion className="accordion-container">
        {Array.isArray(pageData) &&
          pageData.map((item, index) =>
            Array.isArray(item) ? (
              <Accordion.Item key={'Line ' + index} value={index.toString()}>
                <Accordion.Control>
                  <Text c="#757575" size="sm" lineClamp={1}>
                    Line: {getLineText(item)}
                  </Text>
                </Accordion.Control>
                <Accordion.Panel>
                  <Text
                    key={index}
                    data-page={pageNumber}
                    style={{
                      whiteSpace: 'pre-wrap',
                      wordBreak: 'break-word',
                      cursor: 'text',
                      lineHeight: 'normal',
                    }}
                    mb="xs"
                  >
                    {getLineText(item)}
                  </Text>
                </Accordion.Panel>
              </Accordion.Item>
            ) : (
              <Accordion.Item key={'Cluster ' + index} value={index.toString()}>
                <Accordion.Control>
                  <Text c="#757575" size="sm">
                    Cluster: {item.id}
                  </Text>
                </Accordion.Control>
                <Accordion.Panel>
                  {Array.isArray(item.children) ? (
                    <DisplayNestedClusters
                      pageData={item.children}
                      pageNumber={pageNumber}
                    />
                  ) : null}
                </Accordion.Panel>
              </Accordion.Item>
            )
          )}
      </Accordion>
    </div>
  );
};

export default DisplayNestedClusters;
