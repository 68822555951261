import { EphemeralExecuteDataType } from 'Types/commonTypes';

import { request } from './httpService';

const API_VERSION = 'v1';
const pathPrefix = `/api/${API_VERSION}/reviews/`;

const ExpressionService = {
  ephemeralExecute: async (data: EphemeralExecuteDataType) => {
    return request({
      url: `${pathPrefix}expressions/ephemeral-execute/`,
      method: 'post',
      data: data,
    });
  },
};

export default ExpressionService;
