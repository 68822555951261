import { useSelector } from 'react-redux';

import { Box, Divider, Grid, Tabs, Text } from '@mantine/core';
import { IconFile } from '@tabler/icons-react';
import { RootState } from 'Src/redux/store';
import { DocumentDataType } from 'Types/docTypes';

type BatchListProps = {
  documentData: DocumentDataType | null;
};

const BatchList: React.FC<BatchListProps> = () => {
  const documentData = useSelector(
    (state: RootState) => state.activeDocument.data
  );
  const snapshotVersions = documentData?.snapshots || [];

  return (
    <Box p="sm">
      <Text size="sm" mt={10}>
        This section will be used to navigate through different files belonging
        to a same batch
      </Text>
      <Divider my={20} />
      <Tabs defaultValue="file">
        <Tabs.List>
          <Tabs.Tab value="file">File</Tabs.Tab>
          {/* <Tabs.Tab value="info">Info</Tabs.Tab> */}
        </Tabs.List>

        <Tabs.Panel value="file">
          {snapshotVersions.map(({ file_original_name }, index) => (
            <Box key={index} py={10}>
              <Grid>
                <Grid.Col span={12}>
                  <Grid>
                    <Grid.Col span={2}>
                      <IconFile size={16} />
                    </Grid.Col>
                    <Grid.Col span={10}>
                      <Text truncate="end" size="sm">
                        {file_original_name}
                      </Text>
                    </Grid.Col>
                  </Grid>
                </Grid.Col>
                {/* <Grid.Col span={2}>
                  <ActionIcon
                    size="xs"
                    variant="transparent"
                    aria-label="Settings"
                  >
                    <IconTrash />
                  </ActionIcon>
                </Grid.Col> */}
              </Grid>
            </Box>
          ))}
        </Tabs.Panel>
        {/* <Tabs.Panel value="info">Messages tab content</Tabs.Panel> */}
      </Tabs>
    </Box>
  );
};

export default BatchList;
