import { request, generateCRUDOps, API_PATH } from './httpService';

const API_VERSION = 'v1';
const pathPrefix = `/api/${API_VERSION}/reviews/`;

const SnapshotService = {
  ...generateCRUDOps(pathPrefix, 'snapshots'),
  getSnapshotsByDocId: async (id: number) => {
    return request({
      url: `${API_PATH.REVIEWS}documents/${id}/snapshots/`,
      method: 'get',
    });
  },
  markSnapshotAsPrimary: async (id: number, isFavorite: boolean) => {
    return request({
      url: `${pathPrefix}snapshots/${id}/`,
      method: 'patch',
      data: { is_favorite: isFavorite },
    });
  },
  parseSnapshot: async (id: number, force: boolean = false) => {
    return request({
      url: `${pathPrefix}snapshots/${id}/parse/`,
      method: 'patch',
      data: { force },
    });
  },
  getParsedSnapshot: async (id: number) => {
    return request({
      url: `${pathPrefix}snapshots/${id}/parsed/`,
      method: 'get',
    });
  },
  patchParsedSnapshot: async (snapshotId: number) => {
    return request({
      url: `${pathPrefix}snapshots/${snapshotId}/parsed/`,
      method: 'patch',
    });
  },
  patchParsedSnapshotById: async (snapshotId: number, id: number) => {
    return request({
      url: `${pathPrefix}snapshots/${snapshotId}/parsed/${id}/`,
      method: 'patch',
    });
  },
};

export default SnapshotService;
