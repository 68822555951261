import React from 'react';

import { WebViewerInstance } from '@pdftron/webviewer';
import { TextCoordinatesType } from 'Types/fileViewerTypes';

const useTextHighlight = (
  webViewerInstance: WebViewerInstance | null,
  textCoordsArray: TextCoordinatesType[] | null
) => {
  // Create a ref to store all added highlights
  const highlightsRef = React.useRef<any[]>([]);

  React.useEffect(() => {
    if (!webViewerInstance) return;

    const { documentViewer } = webViewerInstance.Core;

    if (!textCoordsArray || textCoordsArray.length === 0) {
      // Remove all highlights if no text coordinates are provided
      if (highlightsRef.current.length > 0) {
        const annotManager = documentViewer.getAnnotationManager();
        highlightsRef.current.forEach((highlight) => {
          annotManager.deleteAnnotation(highlight);
        });
        highlightsRef.current = [];
      }
      return;
    }

    if (!documentViewer.getDocument()) return;

    try {
      const annotManager = documentViewer.getAnnotationManager();

      // Remove all previous highlights
      if (highlightsRef.current.length > 0) {
        highlightsRef.current.forEach((highlight) => {
          annotManager.deleteAnnotation(highlight);
        });
        highlightsRef.current = [];
      }

      textCoordsArray.forEach((textCoords) => {
        // Dynamically scroll to the page with the text selected
        if (documentViewer.getCurrentPage() !== textCoords.page) {
          documentViewer.setCurrentPage(textCoords.page, true);
        }

        const quad = new webViewerInstance.Core.Math.Quad(
          textCoords.x_left,
          textCoords.y_top,
          textCoords.x_right,
          textCoords.y_top,
          textCoords.x_right,
          textCoords.y_bottom,
          textCoords.x_left,
          textCoords.y_bottom
        );

        const highlight =
          new webViewerInstance.Core.Annotations.TextHighlightAnnotation();
        highlight.PageNumber = textCoords.page;
        highlight.Quads = [quad];
        highlight.StrokeColor = new webViewerInstance.Core.Annotations.Color(
          ...(textCoords.highlight_color || [255, 255, 0, 1]) // Default to yellow highlight
        );

        // Mark the annotation as imported so it won't be sent to the database
        annotManager.addAnnotation(highlight, { imported: true });
        annotManager.redrawAnnotation(highlight);

        // Store the new highlight
        highlightsRef.current.push(highlight);

        // Scroll to the annotation after it's been added
        webViewerInstance.Core.documentViewer.displayPageLocation(
          highlight.getPageNumber(),
          0,
          highlight.getRect().y1 - 50 // Subtract offset to position highlight lower in view
        );
      });
    } catch (error) {
      console.error('Error applying text highlight:', error);
    }
  }, [webViewerInstance, textCoordsArray]);
};

export default useTextHighlight;
